import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import References from '../components/references';

function SuccessPage({ location }) {
  const urlParam = new URLSearchParams(location.search).get('conversion');
  useEffect(() => {
    window.gtag = window.gtag || function gtag(...args) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(args);
    };

    // Extract the URL parameter from the location object

    // Send the conversion event to Google Analytics if cookie is not set
    if (typeof window.gtag !== 'undefined' && urlParam && !document.cookie.includes('conversion')) {
      window.gtag('event', urlParam === 'launchplan' ? 'submit_launchplan_form' : 'submit_contact_form', {
        event_category: 'Form Submission',
        event_label: urlParam,
      });

      // save cookie for 1 day to not fire conversion again
      const date = new Date();
      date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
      document.cookie = `conversion=${urlParam}; expires=${date.toUTCString()}; path=/`;
    }
  }, [location]);

  return (
    <>
      <div className="bg-gray-100 h-auto py-24">
        <div className="bg-white p-6 mx-auto w-fit">
          <svg viewBox="0 0 24 24" className="text-latori-green w-16 h-16 mx-auto my-6">
            <path
              fill="currentColor"
              d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
            />
          </svg>
          <div className="text-center">
            <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">{urlParam === 'launchplan' ? 'Vielen Dank für Ihr Interesse!' : 'Vielen Dank!'}</h3>
            <p className="text-gray-600 my-2">{urlParam === 'launchplan' ? 'Den kostenlosen Shopify Launchplan erhalten Sie als PDF, nachdem Sie der Bestätigungsmail zugestimmt haben.' : 'Die Nachricht wurde erfolgreich übermittelt'}  </p>
            <div className="py-10 text-center">
              { urlParam === 'launchplan' ? (
                <Link to="/blog" className="button-primary success-btn">
                  Hier finden Sie noch mehr Shopify Content
                </Link>
              ) : (
                <Link to="/" className="button-primary ">
                  Zurück zur Startseite
                </Link>
              )}

            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-12">
        <References limit={3} />
      </div>
    </>
  );
}

export default SuccessPage;
